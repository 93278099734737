<template>
  <div class="card" :class="{ gotlink: !!data.href }">
    <h3 class="mb-xxs">{{ data.title }}</h3>
    <h4 class="mb-xxxs">{{ data.headline }}</h4>
    <address v-if="data.address" v-html="data.address"></address>
    <p v-if="data.phone" class="info">
      {{ data.phone.label }}
    </p>

    <SvgSprite
      v-if="data.href"
      class="icon-link"
      symbol="icons-link"
      size="16 16"
    />
    <SvgSprite
      v-if="data.href"
      class="icon-arrow"
      symbol="ui-arrow-right"
      size="16 16"
    />

    <a class="link" target="_blank" :href="data.href" v-if="data.href"></a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  props: {
    data: Object,
  } as any, // eslint-disable-line
})
</script>

<style scoped lang="scss">
.card {
  position: relative;
  background: $c-white;
  padding: 20px;
}

.card.gotlink {
  .icon-arrow {
    transition: transform 0.3s;
    will-change: transform;
  }

  &:hover {
    opacity: 0.8;
    .icon-arrow {
      transform: translateX(3px);
    }
  }
}

.link {
  @include get-all-space;
  display: block;
  z-index: 10;
}

.icon-link {
  position: absolute;
  top: 20px;
  right: 20px;
}

.icon-arrow {
  position: absolute;
  bottom: 20px;
  right: 20px;
  stroke: $tangerine;
  stroke-width: 2;
}

h3 {
  @include fira-bold;
  font-size: 14px;
}

h4 {
  @include fira-bold;
  font-size: 14px;
}

address,
p {
  @include fira;
  font-size: 14px;
  line-height: 1.33;
  font-style: normal;
  color: $regal-blue;
}
</style>
