
import { defineComponent, ref } from 'vue'
import gsap from 'gsap'

export default defineComponent({
  name: 'DropdownContactList',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup() {
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const innerAnims = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enter(el: any, done: any) {
        el.style.height = '0'
        done()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      afterEnter(el: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      leave(el: any, done: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      inner,
      innerAnims,
      isOpen,
      toggle,
      vBar,
    }
  },
})
