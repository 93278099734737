<template>
  <div class="contact" v-if="data?.content">
    <Hero title="Contact" :picture="data?.content?.picture"></Hero>

    <div class="list wrapper">
      <BigCard
        class="big"
        v-for="item in data?.content?.contacts?.highlighted"
        :key="item.title"
        :data="item"
      />

      <Card
        v-for="item in data?.content?.contacts?.others"
        :key="item.title"
        :data="item"
      />
    </div>

    <div
      class="contact__dropdowns wrapper"
      v-if="data?.content?.contacts?.economat"
    >
      <h3 class="mt-l mb-s">Economats</h3>

      <DropdownContactList
        :data="dropdown"
        v-for="(dropdown, index) in data?.content?.contacts?.economat"
        :key="`dropdown-economat-${index}`"
      />
    </div>

    <div
      class="contact__dropdowns wrapper"
      v-if="data?.content?.contacts?.region"
    >
      <h3 class="mt-l mb-s">Régions</h3>

      <DropdownContactList
        :data="dropdown"
        v-for="(dropdown, index) in data?.content?.contacts?.region"
        :key="`dropdown-region-${index}`"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { list } from '@/guides/endpoints/contact'
import Hero from '@/components/Hero.vue'
import BigCard from '@/components/contact/BigCard.vue'
import Card from '@/components/contact/Card.vue'
import DropdownContactList from '@/components/DropdownContactList.vue'

export default defineComponent({
  name: 'Contact',
  components: {
    Hero,
    BigCard,
    Card,
    DropdownContactList,
  },
  setup() {
    const data = ref()

    list().then(r => {
      data.value = r.data
    })

    return {
      data,
    }
  },
})
</script>

<style lang="scss" scoped>
.list {
  display: grid;
  max-width: 1200px;
  gap: 20px;
  grid-template-columns: 1fr;
  padding-top: $spacing;

  @include mq($from: 1100px) {
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($from: xxxl) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    .card {
      grid-column: span 2;
    }

    .big {
      grid-column: span 3;
    }

    @include mq($from: s, $until: m) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.contact__dropdowns {
  max-width: 1200px;
}

.card ::v-deep(h4) {
  color: $c-black;
}

.card ::v-deep(address) {
  color: $c-black;
}

.card ::v-deep(p) {
  color: $c-black;
}
</style>
