<template>
  <div class="dropdown" v-if="data.items.length">
    <div class="dropdown__header" @click.prevent="toggle">
      <h4 class="dropdown__header__label">
        {{ data.title }}
      </h4>
      <div class="dropdown__header__icon"></div>
      <svg
        class="dropdown__header__icon"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path class="dropdown__header__icon__horizontal" d="M0 8h18v2H0z" />
        <path
          class="dropdown__header__icon__vertical"
          d="M8 0h2v18H8z"
          ref="vBar"
        />
      </svg>
    </div>
    <transition
      @enter="innerAnims.enter"
      @afterEnter="innerAnims.afterEnter"
      @leave="innerAnims.leave"
    >
      <div class="dropdown-inner" v-if="isOpen">
        <div
          class="dropdown__data"
          v-for="(item, index) in data.items"
          :key="`item-${index}`"
        >
          <span
            class="dropdown__data__title"
            v-if="item.headline"
            v-html="item.headline"
          ></span>
          <span
            class="dropdown__data__description"
            v-if="item.text"
            v-html="item.text"
          ></span>
          <span
            class="dropdown__data__address"
            v-if="item.address"
            v-html="item.address"
          ></span>
          <span class="dropdown__data__mail" v-if="item.email">
            <a :href="item.email.value">{{ item.email.label }}</a>
          </span>
          <span class="dropdown__data__phone" v-if="item.phone">
            <a :href="item.phone.value">{{ item.phone.label }}</a>
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import gsap from 'gsap'

export default defineComponent({
  name: 'DropdownContactList',
  props: {
    data: Object,
  } as any, // eslint-disable-line
  setup() {
    const isOpen = ref(false)
    const vBar = ref<null | HTMLElement>(null)
    const inner = ref<null | HTMLElement>(null)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const innerAnims = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      enter(el: any, done: any) {
        el.style.height = '0'
        done()
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      afterEnter(el: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 'auto',
          ease: 'power4.inOut',
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 0,
            yPercent: 50,
          },
          '=>'
        )
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      leave(el: any, done: any) {
        const tl = gsap.timeline()

        tl.to(el, {
          duration: 0.3,
          height: 0,
          ease: 'power4.inOut',
          onComplete: done,
        })

        tl.to(
          vBar.value,
          {
            duration: 0.3,
            opacity: 1,
            yPercent: 0,
          },
          '=>'
        )
      },
    }

    return {
      inner,
      innerAnims,
      isOpen,
      toggle,
      vBar,
    }
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;

  & + & {
    margin-top: 0;
    border-top: 1px solid $athens-gray;
  }
}

.dropdown__header {
  position: relative;
  padding: $spacing $spacing * 3.5 $spacing $spacing;
  background-color: $c-white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.dropdown__header__label {
  color: $c-black;
}

.dropdown__header__icon {
  @include center-y;
  right: 20px;
  width: 16px;
  height: 16px;
  fill: $tangerine;
}

.dropdown-inner {
  overflow: hidden;
  // height: 0;
  background-color: $botticelli;
}

.dropdown__data {
  margin: $spacing;
  display: flex;
  flex-direction: column;

  @include mq(m) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  > span {
    @include mq($until: m) {
      margin-bottom: 1rem;
    }
    @include mq(m) {
      width: calc(33% - 2rem);

      &:nth-child(3) {
        text-align: right;
      }
    }
  }

  font-size: 1.2rem;
  line-height: 1.6rem;

  padding-bottom: $spacing;
  border-bottom: 1px solid $athens-gray;

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  @include mq(xl) {
    margin-bottom: $spacing * 1.4;
  }
}

.dropdown__data__title,
.dropdown__data__description {
  @include fira-bold;

  color: $hippie-blue;
}

.dropdown__data__description {
  @include mq(m) {
    flex: 100%;
    margin-bottom: $spacing;
  }
}

.dropdown__data__address {
  ::v-deep br {
    display: none;
  }
}
</style>
