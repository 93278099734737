<template>
  <div class="card">
    <div class="action">
      <router-link v-if="data.isCarrick" :to="{ name: 'ContactForm' }">
        <SvgSprite symbol="icons-mail" size="17 13" />
      </router-link>

      <a v-else-if="data.mail" :href="data.mail.value">
        <SvgSprite symbol="icons-mail" size="17 13" />
      </a>

      <a v-if="data.phone" :href="data.phone.value">
        <SvgSprite symbol="icons-phone" size="16 16" />
      </a>
    </div>

    <!-- <div class="picture">
      <img v-src="data.picture" />
    </div> -->

    <h3 class="mt-xs mb-xxs">{{ data.title }}</h3>
    <p>{{ data.text }}</p>

    <div class="mt-m">
      <p v-if="data.phone" class="info">
        {{ data.phone.label }}
      </p>

      <p v-if="data.mail" class="info">
        {{ data.mail.label }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BigCard',
  props: {
    data: Object,
  } as any, // eslint-disable-line
})
</script>

<style scoped lang="scss">
.card {
  background: $c-white;
  padding: 20px;
  position: relative;
}

h3 {
  font-size: 14px;
}

p {
  font-size: 14px;
  line-height: 1.3;
}

.info {
  display: block;
  color: $regal-blue;
  font-size: 14px;
}

.action {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: $athens-gray;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    svg {
      fill: $regal-blue;
    }
  }

  a + a {
    margin-left: 5px;
  }
}

.picture {
  width: 4.6rem;

  img {
    width: 100%;
  }
}
</style>
