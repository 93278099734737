
import { defineComponent, ref } from 'vue'
import { list } from '@/guides/endpoints/contact'
import Hero from '@/components/Hero.vue'
import BigCard from '@/components/contact/BigCard.vue'
import Card from '@/components/contact/Card.vue'
import DropdownContactList from '@/components/DropdownContactList.vue'

export default defineComponent({
  name: 'Contact',
  components: {
    Hero,
    BigCard,
    Card,
    DropdownContactList,
  },
  setup() {
    const data = ref()

    list().then(r => {
      data.value = r.data
    })

    return {
      data,
    }
  },
})
